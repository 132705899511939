/* Top_NavBar */
.Top_NavBar{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2000;
    width: 100%;
    height: 50px;
    background-color: transparent;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 14px 0px;
    padding-right: 10px;
}



.Nav_Content{
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    grid-gap: 10px;
}

.Nav_Ll_Dtxt{
    position: relative;
    display: inline-flex;
    align-items: flex-start;
    gap: 20px;
    grid-gap: 20px;
}
.Nav_Ll_Dtxt .site-logo img{
    display: none;
    width: auto !important;
}
.Nav_Ll_Dtxt .site-logo{
    display: inline-flex;
    align-items: center;
}
.Active_Scroll_Nav.Top_NavBar .Nav_Ll_Dtxt .site-logo img{
    display: inline-flex !important;
}
.Nav_Ll_Dtxt p{
    font-size: 14px;
    display: inline-flex;
    gap: 5px;
    grid-gap: 5px;
    color: #fff;
    font-family: 'Euclid Circular B', '-apple-system', BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

.Nav_Ll_Dtxt p span{
    font-weight: 600;
}

.Nav_Ll_Dtxt p a:hover{
    text-decoration: underline !important;
}
.Rr_Tt_Ul{
    position: relative;
    display: inline-flex;
    align-items: center;
    gap: 10px;
    grid-gap: 10px;
}
.Rr_Tt_Ul li {
    position: relative;
    display: inline-flex;
    align-items: center;
}
.Rr_Tt_Ul li p,
.Rr_Tt_Ul li a{
    position: relative;
    padding: 4px 8px;
    display: inline-flex;
    align-items: center;
    font-size: 15px;
    font-weight: 400;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    user-select: none;
    gap: 5px;
    grid-gap: 5px;
}

.Rr_Tt_Ul li p:hover{
    opacity: 0.7;
    transition: all 0.3s ease-in-out;
}
.Rr_Tt_Ul li a:hover{
    text-decoration: underline;
    color: #5A160D;
    border-color: #5A160D;
}
.Rr_Tt_Ul li p img{
    width: 14px;
    margin-top: 1px;
    transition: all 0.3s ease-in-out;
}
.Active_DropDown .DropDown_BtnNN p{
    opacity: 0.7;
}

.Active_DropDown .DropDown_BtnNN p img{
    transform: rotate(180deg);
}


.OODrop_Down{
    position: fixed;
    width: 100%;
    height: auto;
    max-height: 100vh;
    z-index: 100;
    min-height: 100px;
    background-color: #000;
    top: 90px;
    padding: 30px 0px;
    padding-bottom: 100px;
    color: #fff;
    overflow-y: auto !important;
    transform: translateY(10px);
    opacity: 0;
    -webkit-animation: DropAnim 0.3s ease-in forwards;
    -moz-animation: DropAnim 0.3s ease-in forwards;
    animation: DropAnim 0.3s ease-in forwards;
    display: none;
}

.Active_DropDown .OODrop_Down{
    display: block;
}

.Active_Scroll_Nav .OODrop_Down{
  display: none !important;
}

@keyframes DropAnim{
    from{
        opacity: 0;
        transform: translateY(10px);
    }
    to{
        opacity: 1;
        transform: translateY(0px);
    }
}
@-webkit-keyframes DropAnim{
    from{
        opacity: 0;
        transform: translateY(10px);
    }
    to{
        opacity: 1;
        transform: translateY(0px);
    }
}

@-moz-keyframes DropAnim{
    from{
        opacity: 0;
        transform: translateY(10px);
    }
    to{
        opacity: 1;
        transform: translateY(0px);
    }
}

.OODrop_Down h3.big-text{
    margin-bottom: 30px;
}

.OODrop_Down_Content{
    position: relative;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns:repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 30px;
    grid-template-rows:auto;
}

.OODrop_Down_Card{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.OODrop_Down_Card img.Serv_Drop_Banner{
    position: relative;
    width: 100%;
    height:160px;
    object-fit: cover;
    object-position: center;
    margin-bottom: 10px;
}
.OODrop_Down_Card p{
    font-size: 20px;
    font-weight: 700;
}
.OODrop_Down_Card span.a-button{
    width: 100% !important;
}
.OODrop_Down_Card:hover span{
    background-color: rgba(255,255,255,0.1);
}
/* Main_navBar */
.Main_navBar{
    position:absolute;
    top: 20px;
    left: 0;
    /* background-color: #000; */
    color: #fff;
    width: 100%;
    height: auto;
    z-index: 1000;
}
@media screen and (max-width:1100px){
    .Main_navBar{
        position: sticky !important;
        z-index: 2000;
        padding: 0px !important;
        height: 70px  !important;
        display: flex;
        align-items: center;
        background-color: #000;
        top: 0px !important;
    }

    .Main_navBar .Rr_Tt_Ul{
      display: none !important;
    }
    .First_Mm_Nav{
        height: auto !important;
    }
}
.First_Mm_Nav{
    position: relative;
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Right_R_Main_Nav,
.Left_L_Main_Nav,
.site-logo{
    position: relative;
}
.site-logo img{
    max-width: 150px;
}

.Pp_All_Daf{
    position: relative;
    display: none;
    align-items: center;
    gap: 20px;
    grid-gap: 20px;
}

.Nav_Social_icons{
    position: relative;
    display: inline-flex;
    align-items: center;
    gap: 20px;
    grid-gap: 20px;
    user-select: none;
}
.Nav_Social_icons p{
    font-size: 17px;
    font-weight: 500;
}
@media screen and (max-width:1200px){
    .Nav_Social_icons p{
        font-size: 15px !important;
    }
}
.social_Icons_main svg,
.Nav_Social_icons svg{
  width: 25px !important;
}
@media screen and (max-width:1000px){
  .social_Icons_main svg,
.Nav_Social_icons svg{
  width: 20px !important;
}
}
.social_Icons_main{
    position: relative;
    display: inline-flex;
    align-items: center;
    gap: 10px;
    gap: 10px;
}
.social_Icons_main a{
    position: relative;
    display: inline-flex;
    align-items: center;
    transition: all 0.3s ease-in-out;
    padding: 5px;
}
.social_Icons_main a:hover{
    opacity: 0.8;
}
@media screen and (max-width:1200px){
    .Nav_Social_icons{
        gap: 10px !important;
        grid-gap: 10px !important;
    }
    .social_Icons_main{
        gap: 5px !important;
        grid-gap: 5px !important;
    }
    .social_Icons_main a img{
        width: 15px !important;
        max-height: 15px !important;
    }
}
.Chat_Btn{
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 10px 20px;
    background-color: #5A160D;
    border:1px solid #5A160D;
    gap: 10px;
    grid-gap: 10px;
    font-size: 17px;
    cursor: pointer;
    user-select: none;
    transition: all 0.3s ease-in-out;
}
@media screen and (max-width:1200px){
    .Chat_Btn{
        font-size: 15px !important;
        padding: 9px 18px !important;
    }
    .Chat_Btn img{
        width: 15px !important;
    }
}
@media screen and (max-width:1100px){
    .Chat_Btn{
        font-size: 14px !important;
        padding: 8px 10px !important;
    }
    .Chat_Btn img{
        width: 14px !important;
    }
}
@media screen and (max-width:350px){
    .Chat_Btn span{
        display: none !important;
    }
}
.Chat_Btn:hover{
    background-color: transparent;
    border-color: #fff;
}

.Second_Mm_Nav{
    position: relative;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
}
.Second_Mm_Nav ul{
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    gap: 20px;
    grid-gap: 20px;
}
.Second_Mm_Nav ul li{
    position: relative;
    display: inline-flex;
    align-items: center;
}
.Second_Mm_Nav ul li a{
    position: relative;
    display: inline-flex;
    align-items: center;
    font-size: 13px;
    color: #D2D2D2;
    padding: 10px 0px;
    transition: all 0.3s ease-in-out;
}
.Second_Mm_Nav ul li a:hover{
    color: #fff;
    text-decoration: underline;
}

.Nav_Body,
.Mobile_nav,
.Nav_Toggler{
    display: none;
}

@media screen and (max-width:1100px){
    .Nav_Social_icons,
    .search_btn span,
    .Top_NavBar,
    .Second_Mm_Nav{
        display: none !important;
    }

    .Pp_All_Daf{
      display: inline-flex !important;
    }

    .site-logo img{
        max-width: 120px !important;
    }

    .Pp_All_Daf{
        gap: 10px !important;
        grid-gap: 10px !important;
    }

    .Nav_Toggler{
        position: relative;
        display: inline-flex !important;
        width: 20px;
        height: 30px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .Nav_Toggler span{
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: #fff;
        transition: all 0.3s ease-in-out;
    }
    .Nav_Toggler span:first-child{
        transform: translateY(-7px);
    }
    .Nav_Toggler span:last-child{
        transform: translateY(7px);
    }

    .Active_Mobile_Nav  .Nav_Toggler span:first-child{
        transform: rotate(45deg);
    }
    .Active_Mobile_Nav  .Nav_Toggler span:nth-child(2){
        opacity: 0;
    }
    .Active_Mobile_Nav  .Nav_Toggler span:last-child{
        transform: rotate(-45deg);
    }


    .Nav_Body{
        position: fixed;
        width: 100%;
        height: 100%;
        top: 70px;
        z-index: 1000;
        background-color: rgba(0,0,0,0.5);
        display: none;
        opacity: 0;
        -webkit-animation: FadeIn 0.3s ease-in forwards;
        -moz-animation: FadeIn 0.3s ease-in forwards;
        animation: FadeIn 0.3s ease-in forwards;
    }
    .Active_Mobile_Nav .Nav_Body{
        display: block !important;
    }

    .Mobile_nav{
        position: fixed;
        display: block !important;
        z-index: 2000;
        background-color: #000;
        color: #fff;
        border-top: 1px solid rgba(255,255,255,0.1);
        width: 300px;
        height: 100%;
        overflow-y: auto;
        top: 70px;
        right: -100%;
        transition: all 0.3s ease-in-out;
        padding: 25px !important;
        padding-bottom: 100px !important;
    }
    .Active_Mobile_Nav .Mobile_nav{
        right: 0% !important;
    }
    .Mobile_nav .Nav_Social_icons{
        display: flex !important;
        width: 100% !important;
    }

    .Mobile_nav .Nav_Ll_Dtxt{
        display: block !important;
        margin-bottom: 20px !important;
    }

    .Mmn_UlL1{
        position: relative;
        width: 100%;
        height: auto;
        margin-top: 10px;
        display: block;
    }
    .Mmn_UlL1 li{
        position: relative;
        width: 100%;
        height: auto;
    }
    .Mmn_UlL1 li p{
        position: relative;
        width: 100%;
        user-select: none;
        padding: 13px 0px;
        font-size: 13px;
        text-transform: uppercase;
        font-weight: 500;
    }
    .Mmn_UlL1 li a{
        position: relative;
        width: 100%;
        height: auto;
        padding: 10px 0px;
        font-size: 13px;
        display: flex;
        border-bottom: 1px solid rgba(255,255,255,0.1);
        color: #D2D2D2;
    }
    .Mmn_UlL1 li a:hover{
        color: #fff !important;
    }

    .Mmn_UlL1 li a.contact-btn{
            background: #fff !important;
            color: #5A160D !important;
            justify-content: center;
            text-align: center;
            border-color: transparent;
        }
        .Mmn_UlL1 li a.contact-btn:hover{
            background: linear-gradient(120deg, #e57824, #fc9945);
        }

}

@media screen and (max-width:250px){
    .site-logo img{
        max-width: 100px !important;
    }
}
@media screen and (max-width:500px){
.Mobile_nav{
    width: 100% !important;
}
}




/* ahahhaa_Dropa */

.ahahhaa_Dropa{
  position: absolute;
  top: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  -webkit-animation: FadeInTOp 0.3s ease forwards;
  -moz-animation: FadeInTOp 0.3s ease forwards;
  animation: FadeInTOp 0.3s ease forwards;
  transform: translateY(10px);
  display: none !important;
}
.Rr_Tt_Ul li:hover .ahahhaa_Dropa{
  display: block !important;
}
@keyframes FadeInTOp{
  from{
    transform: translateY(10px);
  }
  to{
    transform: translateY(0px);
  }
}
@-webkit-keyframes FadeInTOp{
  from{
    transform: translateY(10px);
  }
  to{
    transform: translateY(0px);
  }
}
@-moz-keyframes FadeInTOp{
  from{
    transform: translateY(10px);
  }
  to{
    transform: translateY(0px);
  }
}
.ahahhaa_Dropa a{
  white-space: nowrap !important;
  text-align: left !important;
  display: block !important;
  font-size: 13px !important;
}




/* Let_talk_sec */
.Let_talk_sec{
    position: fixed;
    top: 130px;
    right: 10%;
    z-index: 2500;
    display: none;
}

@media screen and (max-width:1100px){
    .Let_talk_sec{
        top: 63px !important;
    }  
}

.Show_Let_talk_sec.Let_talk_sec{
    display: block;
}


.Let_talk_Box{
    position: relative;
    width: 300px;
    height: auto;
    min-height: 200px;
    background-color: #fff;
    color: #000;
    overflow: hidden;
    border-radius: 5px;
    opacity: 0;
    transform: translateY(-30px) scale(0.9);
    -webkit-animation: bounceInFromTop 0.6s forwards; 
    -moz-animation: bounceInFromTop 0.6s forwards; 
    animation: bounceInFromTop 0.6s forwards; 
    -webkit-box-shadow: 0 0 20px rgb(0 0 0 / 20%);
    -moz-box-shadow: 0 0 20px rgb(0 0 0 / 20%);
    box-shadow: 0 0 20px rgb(0 0 0 / 20%);
}
@media screen and (max-width:400px){
    .Let_talk_Box{
        width: 100% !important;
    }
    .Let_talk_sec{
        width: 80% !important;
    }
}

.Let_talk_Banner{
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.Let_talk_Banner img{
    max-width: 100%;
}
.Let_talk_Banner video{
    position: absolute;
    max-width: 50%;
    bottom: 20px;
    left: 20px;
}

.Close_Let_talk_sec{
    position: absolute;
    width: 25px;
    height: 25px;
    background-color: rgba(0,0,0,0.8);
    top: 15px;
    right: 15px;
    border-radius: 50%;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:  center;
    text-align: center;
    user-select: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}
.Close_Let_talk_sec:hover{
    transform: scale(1.2);
}
.Close_Let_talk_sec::before{
    content: '';
    position: absolute;
    width: 50%;
    height: 1px;
    background-color: #fff;
    transform: rotate(45deg);
}
.Close_Let_talk_sec::after{
    content: '';
    position: absolute;
    width: 50%;
    height: 1px;
    background-color: #fff;
    transform: rotate(-45deg);
}

.Let_talk_Dlt{
    position: relative;
    width: 100%;
    height: auto;
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 30px;
}
.Let_talk_Dlt h3{
    font-size: 20px;
    font-weight: 500;
}


/* Ll_btns */

.Ll_btns{
    position: relative;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr auto;
    margin-top: 15px;
    grid-gap: 10px;
}
@media screen and (max-width:400px){
    .Ll_btns{
        display: flex !important;
        flex-direction: column !important;
        grid-gap: 10px !important;
        gap: 10px !important;
    }
    .Ll_btns a{
        font-size: 15px !important;
    }
}
.Ll_btns a{
    position: relative;
    width: 100%;
    height: 45px;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    grid-gap: 5px;
    border:1px solid #DEDEE8;
    border-radius: 5px;
    font-size: 16px;
    white-space: nowrap;
}

.Ll_btns a img{
    width: 20px;
}
.Ll_btns a.whatsapp-btn{
    background-color: #30D14E;
    border-color: #30D14E;
    color: #fff;
}














/* header-carousel */


.header-carousel {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden !important;
}
.carousel-item {
  background-color: #000;
  position: relative;
  width: 100%;
  height: auto;
  z-index: 1;
}
.carousel-item::before{
  content: '';
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.5);
}

.carousel-item img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  z-index: -10;
  position: relative;
}
@media screen and (max-width:1000px){
  .carousel-item img {
      height: 90vh !important;
  }
  .carousel-text {
    width: 100% !important;
  }
}


.carousel-text {
  position: absolute;
  z-index: 100;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  color: #fff;
  width: 80%;
}
.carousel-text .site-container{
  margin-left: -200px;
  transition: all 0.5s ease-in-out;
}


.carousel-text span{
  opacity: 0;
  transform: translateY(50px) scale(1.5);
  display: inline-flex;
}

.slick-slide.slick-active .carousel-item .carousel-text .site-container{
  margin-left: 0px; 
}



/* Customize the dots */
.header-carousel  .slick-dots {
 bottom: 40px !important;
  position: absolute !important;
  z-index: 100 !important;
}

.header-carousel  .slick-dots li{
  width: 10px !important;
  height: 10px !important;
  margin: 3px 5px !important;
}

.header-carousel  .slick-dots li button:before {
  font-size: 10px !important;
  color: #fff !important;
}

.header-carousel  .slick-dots li.slick-active button:before {
  color: #fff !important; /* Active dot color */
  opacity: 1 !important;
}

/* Arrow styling */
.slick-prev, .slick-next {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  z-index: 1;
  position: absolute !important;
}
@media screen and (max-width:1000px){
  .slick-prev, .slick-next {
    display: none !important;
  }
}
.slick-prev {
  left: 50px !important;
}

.slick-next {
  right: 50px !important;
}

.slick-next svg{
  margin-left: -20px !important;
  fill: #fff !important;
}
.slick-arrow::before{
  display: none !important;
}

.slick-prev svg{
  fill: #fff !important;
}



.particle-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

  /* TopPP_Sec */

  .TopPP_Sec{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 40px;
  }

  .TopPP_Sec_Main{
    position: relative;
    width: 100%;
    height: auto;
    padding: 40px 50px;
    /* border-bottom: 6px solid #E6E5E5; */
    text-align: center;
  }

  .TopPP_Sec_Main p{
    font-size: 20px;
    font-weight: 300;
  }

  @media screen and (max-width:1000px){
    .TopPP_Sec_Main p{
        font-size: 17px !important;
    }
    .TopPP_Sec_Main{
        padding: 40px 0px !important;
    }
  }
  @media screen and (max-width:500px){
    .TopPP_Sec_Main{
        padding: 30px 0px !important;
    }
    .TopPP_Sec_Main p{
        font-size: 15px !important;
    }
  }
  @media screen and (max-width:400px){
    .TopPP_Sec_Main p{
        font-size: 14px !important;
    }
  }

  .Ffal_Ul{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 0px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
    @media screen and (max-width:1000px){
    .Ffal_Ul li{
        gap: 20px !important;
        grid-gap: 20px !important;
    }
    .Ffal_Ul li img{
        max-width: 70px !important;
    }
  }

  @media screen and (max-width:700px){
    .Ffal_Ul{
      display: flex !important;
        flex-direction: column !important;
        gap: 30px !important;
        grid-gap: 30px !important;
    }
    .Ffal_Ul li{
        flex-direction: column !important;
        justify-content: center !important;
        text-align: center !important;
        gap: 15px !important;
        grid-gap: 15px !important;
    }
    .Ffal_Ul li h3{
        font-size: 17px !important;
      }
  }
  .Ffal_Ul li{
    position: relative;
    display: inline-flex;
    align-items: center;
    gap: 30px;
    grid-gap: 30px;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    height: 200px;
  }

  .Ffal_Ul li h3{
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
  }

  .Ffal_Ul li img{
    max-width: 60px;
  }



  /* Grand_GGe_Sec */

  .Grand_GGe_Sec{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 30px;
  }

  .Grand_GGe_Main{
    position: relative;
    width: 100%;
    height: auto;
    min-height: 100px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
  @media screen and (max-width:1000px){
    .Grand_GGe_Main{
        display: block !important;
    }
    .REV_Grand_GGe{
        display: flex !important;
        flex-direction: column-reverse !important;
    }
    .Grand_GGe_banner{
        height: auto !important;
        margin-bottom: -10px !important;
    }
    .Grand_GGe_Txt,
    .Grand_GGe_20  .Grand_GGe_Txt{
      margin-bottom: 30px !important;
    }
  }

  .Grand_GGe_1{
    background-color: #E6E5E5;
  }
  .Grand_GGe_2{
    background-color: #F8E6E1;
  }
  .Grand_GGe_3{
    background-color: #F7D5C2;
  }
  .Grand_GGe_4{
    background-color: #f2f2f2;
  }

  .Grand_GGe_banner{
    position: relative;
    width: 100%;
    height: 100%;
  }
  .Grand_GGe_banner img{
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .Grand_GGe_Txt{
    position: relative;
    width: 100%;
    height: auto;
    padding: 80px 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .Grand_GGe_20  .Grand_GGe_Txt{
    padding: 100px 150px !important;
  }
@media screen and (max-width:1200px){
  .Grand_GGe_Txt,
  .Grand_GGe_20  .Grand_GGe_Txt{
    padding: 0px !important;
  }
  .Grand_GGe_20 h2{
    font-size: 40px !important;
  }
}
  .Grand_GGe_Txt h2{
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  .Grand_GGe_20 h2{
    font-size: 80px;
    line-height: 110%;
    margin-bottom: 40px;
  }
  .Grand_GGe_Txt p{
    font-size: 15px;
  }
  .Grand_GGe_Txt a{
    margin-top: 60px !important;
  }
  .Grand_GGe_20  a{
    margin-top: 80px;
  }
  @media screen and (max-width:1000px){
    .Grand_GGe_20 h2{
      margin-bottom: 20px !important;
    }
    .Grand_GGe_Txt a,
    .Grand_GGe_20  a{
      margin-top: 40px !important;
    }
  }
  .Artllai_Sec .Grand_GGe_Txt{
    text-align: left !important;
  }
  .Grand_GGe_20 .Grand_GGe_banner{
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  .Grand_GGe_20 .Grand_GGe_banner img{
    max-width: 100% !important;
    max-height: 80% !important;
  }
  
  .Artllai_Sec .Grand_GGe_Txt p{
    margin-top: 20px !important;
    font-family: inherit !important;
    font-size: 16px !important;
  }
  
@media screen and (max-width:500px){
    .Grand_GGe_Txt h2{
        font-size: 25px !important;
    }
    .Artllai_Sec .Grand_GGe_Txt p,
    .Grand_GGe_Txt p{
        font-size: 15px !important;
    }
}



/* Latest_Services_Sec */
.Latest_Services_Sec {
    position: relative;
    width: 100%;
    height: auto;
    margin: 70px 0px;
  }
  
  @media screen and (max-width:1000px) {
    .Latest_Services_Sec {
      margin: 50px 0px !important;
    }
  }
  
  .latest-services-slider {
    position: relative;
    width: 100%;
    height: auto;
  }
  
  .Latest_Services_Header {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  @media screen and (max-width:600px) {
    .Latest_Services_Header {
      display: block !important;
    }
    .Slider_Control_Btns {
      margin-top: 10px !important;
    }
  }
  
  .Latest_Services_Slider {
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 40px;
  }
  
  .service-card {
    position: relative;
    width: 100%;
    height: auto;
    padding: 40px 15px !important;
    background-color: transparent !important;
    text-align: left !important;
    border-radius: 0px !important;
  }
  
  @media screen and (max-width:600px) {
    .service-card {
      padding: 0px !important;
    }
    .Service_Card_Main img {
      height: 250px !important;
    }
  }
  
  .Service_Card_Main {
    position: relative;
    width: 100%;
    display: block;
    box-shadow: 0 0  20px rgb(0 0 0 / 10%);
  }
  
  .Service_Card_Main img {
    position: relative;
    width: 100%;
    height: 209px;
    object-fit: cover;
    object-position: center;
    transition: all 0.3s ease-in-out;
  }
  

  
  .Service_Card_Dlt {
    position: relative;
    width: 100%;
    height: auto;
    padding: 50px;
    /* border: 7px solid #E1E7E5; */
  }
  .Service_Card_Dlt_2{
    border-color: #D3D8BA !important;
  }
  .Service_Card_Dlt_3{
    border-color: #F7D5C2 !important;
  }

 
  .Service_Card_Dlt h3 {
    font-size: 15px;
    font-weight: 400;
    text-transform: uppercase;
  }
  
  @media screen and (max-width:500px) {
    .Service_Card_Dlt h3 {
      font-size: 14px !important;
    }
  }

  
  
  .Service_Card_Dlt p {
    font-size: 15px;
    color: #525252;
    margin-top: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  
  .more-link-btn,
  .service-link-btn{
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
    grid-gap: 10px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    margin-top: 50px;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    color: #6d1c0f;
  }
.more-link-btn img,
  .service-link-btn img{
    width: 5px !important;
    height: auto !important;
  }

  .more-link-btn:hover,
  .service-link-btn:hover{
    gap: 15px;
    grid-gap: 15px;
  }

  .prev-button{
    background-color: transparent;
    width: 30px !important;
    height: 60px !important;
    z-index: 100;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    cursor: pointer;
}
.next-button{
    width: 30px !important;
    height: 60px !important;
    background-color: transparent;
    z-index: 100;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    cursor: pointer;
}
.next-button svg,
.prev-button svg{
    width: 10px !important;
}

.sss_Slider_Icons{
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 200px;
  grid-gap: 200px;
  margin-top: -15px;
}





/* HHga_Secs */

.HHga_Secs{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 50px;
}

.HHga_Main{
    position: relative;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 40px;
}

@media screen and (max-width:1200px){
    .HHga_Main{
        display: flex !important;
        flex-direction: column !important;
        grid-gap: 40px !important;
        gap: 40px !important;
    }
    .HHga_1{
        position: relative !important;
        width: 100% !important;
        top: 0px !important;
    }
}

.HHga_1{
    position: sticky;
    top: 70px;
    left: 0;
    z-index: 100;
    width: 400px;
    min-height: 300px;
}
.HHga_1_main{
    position: inherit;
    top: inherit;
    left: inherit;
    z-index: inherit;
    width: inherit;
    min-height: inherit;
    border:7px solid #E6E5E5;
    padding: 30px;
}



.News_header{
    position: relative;
    width: 100%;
    text-align: center;
}

.news_list{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 20px;
    display: block;
}
.news_list li{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 30px;
    display: block;
}


.news_list_AAAa{
    position: relative;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 20px;
}


.NewN_1{
    position: relative;
    text-align: center;
    color: #5A160D;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: space-between;
}
@media screen and (max-width:300px){
    .news_list_AAAa{
        display: block !important;
    }
    .NewN_1{
        display: block !important;
        margin-bottom: 20px !important;
    }
}

.NewN_1 h4{
    margin-bottom: 10px;
    font-weight: 600;
    line-height: 100% !important;
}
.NewN_1 span{
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
}

.NewN_2{
    position: relative;
    width: 100%;
    height: auto;
}

.NewN_2 p{
    font-size: 14px;
    font-weight: 600;
}

.news_list_AAAa:hover .NewN_2 p{
    text-decoration: underline;
}

.NewN_2 span{
    font-size: 13px;
    display: block;
    margin-top: 15px;
}

.NNews_Foot{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.gaggaga_Gris{
  position: relative;
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 450px;
  grid-gap: 50px;
  margin-top: 50px;
  margin-bottom: 100px;
}
@media screen and (max-width:1200px){
  .gaggaga_Gris{
    display: block !important;
  }
}

.alksj_sec{
  position: sticky;
  width: 100%;
  height: auto;
  min-height: 200px;
  top: 30px;
  z-index: 1000;
  left: 0;
  padding: 0px 30px;
}
@media screen and (max-width:1200px){
  .alksj_sec{
    display: none !important;
  }
}
.alksj_Box{
  position: inherit;
  height: inherit;
  top: inherit;
  min-height: inherit;
  z-index: inherit;
}
.alksj_Box_Main{
  position: relative;
  width: 100%;
  height: auto;
  background-color: #fff;
  padding:20px 30px;
  -webkit-box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  -moz-box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  margin-top:20px !important;
}
.alksj_Box ul{
  position: relative;
  width: 100%;
  margin:0px 20px !important;
}

.alksj_Box ul li{
  list-style: disc !important;
  font-size: 13px !important;
  margin-top: 20px !important;
  font-weight: 500;
}

.HHga_2{
    position: relative;
    width: 100%;
    height: auto;
    border-right:1px solid #E6E5E5 ;
    padding: 0px 100px;
}
@media screen and (max-width:1200px){
  .HHga_2{
    padding: 0px !important;
    border: none !important;
  }
}

.HHga_2_Box{
    position: relative;
    width: 100%;
    height: auto;
    display: block;
    margin-top: 50px;
}
.HHga_2_Box:first-child{
  margin-top: 0px !important;
}
@media screen and (max-width:1200px){
    .HHga_2_Box{
        display: flex !important;
        flex-direction: column !important;
        grid-gap: 20px !important;
        gap: 20px !important;
    }
    .RevV_HHga{
        flex-direction: column-reverse !important;
    }
}
.HHga_2_Box:first-child{
    margin-top: 30px !important;
}



.HHga_2_Box_Dlt{
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    margin: 30px 0px;
}
.HHga_2_Box_Dlt span{
    color: #5A160D;
    font-size: 13px;
}
.HHga_2_Box_Dlt h2{
    margin: 10px 0px;
    font-size: 30px;
    font-weight: normal;
    margin-bottom: 20px;
}
@media screen and (max-width:700px){
    .HHga_2_Box_Dlt h2{
        font-size: 20px !important;
        font-weight: 600 !important;
    }
}
.HHga_2_Box_Dlt ul,
.HHga_2_Box_Dlt ol{
  position: relative;
  margin: 0;
  padding: 0;
  margin-left: 20px;
  margin-top: 30px;
}
.HHga_2_Box_Dlt ol li{
  list-style: decimal;
  margin-top: 10px;
  font-size: 14px;
  line-height: 150%;
  color: #3E3E3E !important;
}
.HHga_2_Box_Dlt ul li{
  list-style:disc;
  margin-top: 10px;
  font-size: 14px;
  line-height: 150%;
  color: #3E3E3E !important;
}
.HHga_2_Box_Banner{
    position: relative;
    width: 100%;
    height: auto;
}

.HHga_2_Box_Img{
    position: relative;
    width: 100%;
    height: auto;
    z-index: 1;
}

.HHga_2_Box_Img img{
    position: relative;
   width: 100%;
    height: auto;
    max-height: 500px;
    object-fit: cover;
    object-position: top center;
}
@media screen and (max-width:500px){
  .HHga_2_Box_Img img{
    max-height: 100% !important;
  }
}
@media screen and (max-width:500px){
    .HHga_2_Box_Img::after{
        width: 80px !important;
        height: 70px !important;
        left: 10px !important;
    }
}




.pools-h3{
  margin: 20px 0px !important;
  text-transform: uppercase !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}























   /* site-footer */

   .site-footer{
    position: relative;
    width: 100%;
    height: auto;
    padding: 100px 0px;
    background-color: #000;
    color: #fff;
    margin-top: 50px;
    z-index: 300;
   }


   .footer-content{
    position: relative;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    grid-gap: 20px;
   }
@media screen and (max-width:800px){
    .footer-content{
        display: block !important;
    } 
}

   .Ff_Foot_Part{
    position: relative;
    width: 100%;
    height: auto;
   }

   .foot_Logo img{
    max-width: 100%;
   }
   @media screen and (max-width:500px){
    .site-footer{
        padding: 40px 0px !important;
    }
    .foot_Logo img{
        max-width: 120px !important;
       }
       .Ff_Foot_Part_Cards{
        margin-top: 30px !important;
       }
   }
   .Ff_Foot_Part_Cards{
    position: relative;
    width: 100%;
    display: flex;
    gap: 40px;
    grid-gap: 40px;
    margin-top: 40px;
   }
   @media screen and (max-width:420px){
    .Ff_Foot_Part_Cards{
        flex-direction: column !important;
    }
   }
   .vvaf_Box_BoTmm,
   .Ff_Foot_Part_Card{
    position: relative;
   }

   .Ff_Foot_Part_Card h3{
    font-size: 18px;
    font-weight: 700;
   }
   .vvaf_Box_BoTmm ul,
   .Ff_Foot_Part_Card ul{
    position: relative;
    margin-top: 20px;
   }
   .vvaf_Box_BoTmm ul li,
   .Ff_Foot_Part_Card ul li{
    position: relative;
    margin-top: 20px;
   }
   .vvaf_Box_BoTmm ul li h4,
   .Ff_Foot_Part_Card ul li h4{
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
   }
   .vvaf_Box_BoTmm ul li p,
   .Ff_Foot_Part_Card ul li p{
    display: block;
    margin-top: 7px;
    font-size: 14px;
   }

   /* Ss_Foot_Part */

   .Ss_Foot_Part{
    position: relative;
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-top: 100px;

   }
   @media screen and (max-width:800px){
    .Ss_Foot_Part{
        padding-top: 0px !important;
        display: block !important;
        margin-top: 40px !important;
    }
    .Ss_Foot_Part_Ull{
        text-align: left !important;
        display: block !important;
        margin-top: 30px !important;
        margin-left: -10px !important;
    }
}

   .Ss_Foot_Part_Ull{
    position: relative;
    text-align: right;
    margin-top: 50px;
   }
   .Ss_Foot_Part_Ull li{
    position: relative;
    display: inline-flex;
    align-items: center;
    margin: 5px 10px;
   }
   .Ss_Foot_Part_Ull li a{
    font-size: 14px;
    color: #BFBFBF !important;
   }
   .Ss_Foot_Part_Ull li a:hover{
    text-decoration: underline;
   }



   .MM_SUb_Footer{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 50px;
    padding-top: 30px;
    border-top: 1px solid rgba(255,255,255,0.15);
   }

   .MM_SUb_Footer p{
    font-size: 14px;
    color: #BFBFBF !important;
   }

   .Ss_Foot_Part .Nav_Social_icons{
    display: inline-flex !important;
   }
   @media screen and (max-width:450px){
    .Ss_Foot_Part_Ull li a{
        font-size: 12px !important;
    }
}

   @media screen and (max-width:420px){
    .Ss_Foot_Part .Nav_Social_icons {
        display: block !important;
    }
    .Ss_Foot_Part .Nav_Social_icons  p{
        margin-bottom: 10px !important;
    }
    .Ss_Foot_Part .Nav_Social_icons span{
        display:none !important;
    }
   }























   /* AAA_BBab_Page */

   .AAA_BBab_Page{
    position: relative;
    width: 100%;
    height: auto;
    min-height: 100vh;
    background-color: #000;
    color: #fff;
    display: flex;
    align-items: center;
    background-image:linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)),url(./components/Img/Prol_ABT_BG.jpg);
    background-size: cover;
    background-position:center;
    background-repeat: no-repeat;
    z-index: 1;
   }

   .AAA_BBab_Txt{
    position: relative;
    width: 500px;
   }



   .AAA_BBab_Txt h2{
    margin-bottom: 30px !important;
   }

   .AAA_BBab_Txt p{
    font-size: 18px;
    line-height: 30px !important;
   }
   @media screen and (max-width:600px){
    .AAA_BBab_Txt{
        width: 100% !important;
    }
    .AAA_BBab_Txt h2{
      margin-bottom: 20px !important;
     }
    .AAA_BBab_Txt p{
      font-size: 15px !important;
      line-height: 150% !important;
     }
   }
   .BottomCurve{
    position: absolute;
    z-index: -100;
    bottom: -1px;
    left: 0px;
    width: 100%;
    max-height: 40px;
   }


   .LLlakk{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 50px;
   }

   .LLlakk_Grid{
    position: relative;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
   }

   @media screen and (max-width:1000px){
    .LLlakk_Grid{
        display: flex !important;
        flex-direction: column !important;
        gap: 40px !important;
        grid-gap: 40px !important;
    }
   }

   .LLlakk_Card_1{
    position: relative;
    width: 100%;
    height: auto;
   }

   .LLlakk_Card_1 h2{
    margin-bottom: 20px !important;
   }
   .LLlakk_Card_2{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
   }

   .LLlakk_Card_2 img{
    max-width: 100%;
   }


   .GGatg_CArds{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 50px;
   }
   .GGatg_CArds h2{
    text-align: center;
    text-transform: uppercase;
    font-size: 18px;
   }
   @media screen and (max-width:1000px){
    .GGatg_CArds h2{
      font-size: 16px !important;
    }
   }

   .GGatg_CArds ul{
    position: relative;
    width: 70%;
    margin-left: 15%;
    height: auto;
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
    grid-gap: 20px;
    grid-template-rows: auto;
   }
   @media screen and (max-width:1000px){
    .GGatg_CArds ul{
      width: 100% !important;
      margin-left: 0% !important;
    }
   }
   .GGatg_CArds ul li{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
   }
   .GGatg_CArds img{
    max-height: 80px;
    max-width: 100%;
   }

   @media screen and (max-width:500px){
    .GGatg_CArds img{
        max-height: 80px !important;
        max-width:80px !important;
       }
   }

   .Hhhaa_Sec{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 80px;
    padding: 80px 0px;
    background-color: #000;
    color: #fff;
   }

   .Hhhaa_header{
    position: relative;
    width: 100%;
    height: auto;
    text-align: center;
   }


   .Hhhaa_Main{
    position: relative;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
   }
   @media screen and (max-width:1000px){
    .Hhhaa_Main{
        display: flex !important;
        flex-direction: column !important;
        gap: 40px !important;
        grid-gap: 40px !important;
    }
    .Hhhaa_Dlt{
      padding: 0px !important;
    }
   }

   .Hhhaa_Dlt{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    padding:0px 50px;
   }

   .Hhhaa_PRrta{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 40px;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 40px;
   }
   @media screen and (max-width:500px){
    .Hhhaa_PRrta{
      display: flex !important;
      flex-direction: column !important;
      align-items: flex-start !important;
      gap: 30px !important;
      grid-gap: 30px !important;
    }
   }
   .Hhhaa_PRrta:first-child{
    margin-top: 0px !important;
   }

   .hahaq_1{
    position: relative;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
   }
   .hahaq_1 img{
    max-width: 50px;
   }
   .hahaq_2{
    position: relative;
    width: 100%;
    height: auto;
   }


   .Hhhaa_PRrta h3{
    font-size: 17px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 20px !important;
   }
   .Hhhaa_PRrta p{
    font-size: 15px;
  }

  .Hhhaa_Banner{
    position: relative;
    width: 100%;
    height: auto;
  }

  .Hhhaa_Banner img{
    max-width: 100%;
  }

  .kakaj_mman{
    text-align: center !important;
  }



  .VVvava_Sec{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 80px;
  }

  @media screen and (max-width:1000px){
    .VVvava_Sec{
      margin-top: 50px !important;
    }
  }

  .VVvava_Header{
    position: relative;
    width: 100%;
    height: auto;
  }

  .VVvava_Main{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 20px;
    grid-template-rows: auto;
  }

  @media screen and (max-width:700px){
    .VVvava_Main{
        display: flex !important;
        flex-direction: column !important;
        gap: 20px !important;
        grid-gap: 20px !important;
        margin-top: 40px !important;
    }
    .VVvava_Card{
      padding:40px 30px !important;
    }
   }

  .VVvava_Card{
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    background-color: #5A160D;
    color: #fff;
    padding: 40px;
  }

  .VVvava_Card img{
    position: relative;
    width: 100%;
    height: auto;
  }



  .iaiiaia_Imgs .VVvava_Card img{
    object-fit: contain !important;
    max-width: 50px !important;
    margin-bottom: 30px !important;
  }

  .VVvava_Card_Dlt{
    position: relative;
    width: 100%;
    height:100%;
    /* padding: 30px; */
    /* border: 7px solid #E1E7E5; */
  }
  .VVvava_Card:nth-child(2) .VVvava_Card_Dlt{
    border-color: #D3D8BA !important;
  }
  .VVvava_Card:nth-child(3) .VVvava_Card_Dlt{
    border-color: #F7D5C2 !important;
  }
  .VVvava_Card_Dlt h3{
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px !important;
    text-transform: uppercase !important;
  }

  .VVvava_Card_Dlt p{
    font-size: 14px !important;
  }




  .IIIiiD{
    background-color: #000 !important;
    background-image:linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.3)),url(./components/Img/Indus_Bg.jpg) !important;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh !important;
  }

  .IIIiiD_Video{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    top: 0;
    left: 0;
    z-index: -1000;
  }
  .IIIiiD::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -999;
    background-color: rgba(0,0,0,0.3);
  }




  .Career_Hhag{
    position: relative;
    width: 100%;
    height: auto;
    min-height: 90vh;
    background-color: #000;
    color: #fff;
    display: flex;
    align-items: center;
    background-image:linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.3)),url(./components/Img/Career-banner.jpg);
    background-size: cover;
    background-position:center;
    background-repeat: no-repeat;
    z-index: 1;
    display: flex;
    align-items: center;
  }



  /* OOoia_sec */

  .OOoia_sec{
    position: relative;
    width: 100%;
    height: auto;
    z-index: 100;
   margin-top: 50px;
  }

  .OOoia_MAin{
    position: relative;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 150px;
  }
  @media screen and (max-width:1200px){
    .OOoia_MAin{
        grid-gap: 50px !important;
    }
  }
  @media screen and (max-width:1000px){
    .OOoia_MAin{
        display: block !important;
    }
  }

  .OOoia_Part_1{
    position: relative;
    width: 100%;
    height: auto;
  }

  .gagffa{
    position: relative;
    width: 100%;
    height: auto;
  }

  .gagffa p{
    display: block;
    margin-top: 10px !important;
  }
  .gagffa p.noticcs-P{
    font-style: italic !important;
    margin-top: 50px !important;
    color: #5A160D !important;
  }
  .gagffa p.noticcs-P span{
    font-weight: 700 !important;
  }
  .gagffa h2{
    margin-bottom: 40px !important;
  }

  .job-section{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 50px;
  }

  .job-box{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 15px;
    padding: 15px 0px;
    border-bottom: 1px solid #B5B5B5;
  }

  .job-Top{
    position: relative;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 20px;
  }

  @media screen and (max-width:500px){
    .job-Top{
        display: flex !important;
        flex-direction: column !important;
        gap: 20px !important;
        grid-gap: 20px !important;
    }
  }

  .job-Top-1{
    position: relative;
    width: 100%;
    height: auto;
  }

  .job-Top-1 span{
    font-size: 14px;
    color: #5A160D;
  }
  .job-Top-1 h3{
    font-size: 20px;
    font-weight: normal;
    display: block;
    margin-top: 8px;
  }

  .job-Top-2{
    position: relative;
    display: flex;
    align-items: center;
  }

  .job-Top-2 button{
    position: relative;
    width: 150px;
    height: 50px;
    display: inline-flex;
    align-items: center;
    border:2px solid #5A160D;
    color: #5A160D;
    justify-content: center;
    text-align: center;
    gap: 10px;
    grid-gap: 10px;
    font-size: 13px;
    transition: all 0.3s ease-in-out;
    text-transform: uppercase;
    font-weight: 600;
    background-color: transparent;
    cursor: pointer;
  }
  .job-Top-2 button:hover{
    background-color: #e9e8e8;
  }

  .job-description{
    margin-top: 20px;
  }

  .OOoia_Part_2{
    position: sticky;
    top: 70px;
    left: 0;
    z-index: 100;
    width: 500px;
    min-height: 300px;
}
@media screen and (max-width:1200px){
    .OOoia_Part_2{
        width: 400px !important;
    }
}
@media screen and (max-width:1000px){
    .OOoia_Part_2{
        position: relative !important;
        width: 100% !important;
        top: 0px !important;
        margin-top: 40px !important;
    }
}
.Career-form{
    position: inherit;
    top: inherit;
    left: inherit;
    z-index: inherit;
    width: inherit;
    min-height: inherit;
}

.Career-form h2{
  font-size: 18px !important;
  font-weight: 400 !important;
  text-transform: uppercase !important;
}


.Sec-form{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 30px;
    display: block;
}

.form-input{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 20px;
    display: block;
}
.form-input select,
.form-input input{
    position: relative;
    width: 100%;
    height: 50px;
    padding: 0px 15px;
    background-color: transparent;
    border:1px solid #CBD5DE;
    font-size: 15px;
}

.form-input input::placeholder{
    color: #fff;
}
.form-input input[type="file"]{
    padding-top: 13px;
}
.form-input select:focus,
.form-input input:focus{
   border-width: 2px;
}

.form-input select option{
    color: #000 !important;
}

.form-input label{
    display: block;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
}

.form-input button{
    position: relative;
    width: 100%;
    height: 50px;
    padding: 0px 15px;
    background-color: #5A160D;
    border:1px solid #5A160D;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}





/* news_Page */

.news_Page{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 20px;
}

.news_Main{
    position: relative;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 0.7fr;
    grid-gap: 50px;
}


.news_Main_1{
    position: sticky;
    top: 70px;
    left: 0;
    z-index: 100;
    width: 100%;
    min-height: 200px;
}

@media screen and (max-width:1000px){
    .news_Main{
        display: block !important;
    }
    .news_Main_1{
        position: relative !important;
        width: 100% !important;
        top: 0px !important;
        margin-top: 40px !important;
        min-height: 0px !important;
    }
}
.news_Main_1_Cont{
    position: inherit;
    top: inherit;
    left: inherit;
    z-index: inherit;
    width: inherit;
    min-height: inherit;
}
.news_Main_1_Cont p{
    font-size: 14px;
    font-weight: 500;
}
.news_Main_1_Cont img{
    max-width: 100%;
    margin-top: 15px;
}

.News_Listtt{
    position: relative;
    width: 100%;
    margin-top: 30px;
}

.News_Listtt h2{
    text-align: left !important;
}

.News_Listtt .news_list li{
    border-bottom: 1px solid #c3c2c2;
    padding-bottom: 20px;
}

.News_Listtt .news_list li a.news_list_AAAa {
    display: grid !important;
}


.Contact_Pappa{
    position: relative;
    width: 100%;
    height: auto;
    z-index: 1;
}
@media screen and (max-width:800px){
  .Contact_Pappa{
    display: block !important;
  }
  .conatct-banner{
    min-height: 200px !important;
    padding: 50px 0px !important;
  }
  .contact-Dlts{
    padding: 50px 0px !important;
  }
}
.conatct-banner{
    position: relative;
    width: 100%;
    min-height: 300px;
    padding: 100px 0px;
    background-color: #000;
    background-image:linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.3)),url(./components//Img/contactBg.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: #fff;
    display: flex;
    align-items: center;
    z-index: 1;
}


.contact-Dlts{
    position: relative;
    width: 100%;
    height: auto;
    padding:40px 30px;
}

.contact-Dlts-header{
    position: relative;
    width: 100%;
    height: auto;
}

.contact-Dlts-header p{
    color: #4E4E4E !important;
    display: block;
    margin-top: 20px;
    font-size: 15px;
}










.Contact_SeccO_1{
    position: relative;
    width: 100%;
    margin-top: 40px;
}

.Contact_SeccO_1 ul{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 50px !important;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 20px;
    grid-template-rows: auto;
  }
  @media screen and (max-width:500px){
    .Contact_SeccO_1 ul{
      display: flex !important;
      flex-direction: column !important;
      gap: 20px !important;
    }
  }
  .Contact_SeccO_1 ul li{
    position: relative;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 30px;
  }

  @media screen and (max-width:350px){
    .Contact_SeccO_1 ul li{
        display: flex !important;
        flex-direction: column !important;
        grid: 20px !important;
        grid-gap: 20px !important;
    }
  }

  .Contact_SeccO_1 ul li span{
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 10px;
    border:1px solid #DEDEE8;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .Contact_SeccO_1 ul li span img{
    max-width: 100%;
  }

  .Contact_SeccO_1 ul li .DDl_Div{
    position: relative;
    width: 100%;
    height: auto;
  }

  .Contact_SeccO_1 ul li .DDl_Div h3{
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px !important;
  }

  .Contact_SeccO_1 ul li .DDl_Div p{
    font-size: 14px;
    color:#343434;
  }

  .Contact_SeccO_1 ul li .DDl_Div h4{
    font-size: 15px;
    font-weight: 500;
    margin-top: 5px !important;
  }
  .Contact_SeccO_1 ul li .DDl_Div h4 a{
    white-space: nowrap;
    color: #5A160D;
  }
  .Contact_SeccO_1 ul li .DDl_Div h4 a:hover{
    text-decoration: underline;
  }



.message_Sec{
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 40px;
}



.message-form{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 30px;
    display: block;
}

.message-form-input{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 20px;
    display: block;
}
.message-form-input textarea,
.message-form-input select,
.message-form-input input{
    position: relative;
    width: 100%;
    height: 50px;
    padding: 0px 15px;
    background-color: transparent;
    border:1px solid #CBD5DE;
    font-size: 15px;
}
.message-form-input textarea::placeholder,
.message-form-input::placeholder{
    color: #4E4E4E;
}

.message-form-input textarea:focus,
.message-form-input select:focus,
.message-form-input input:focus{
   border-width: 2px;
   border-color: #000 !important;
}

.message-form-input textarea{
  height: 200px;
  padding: 15px !important;
  resize: none;
}

.message-form-input select option{
    color: #000 !important;
}



.message-form-input button{
    position: relative;
    width: 250px;
    border-radius: 5px;
    height: 50px;
    padding: 0px 15px;
    background-color: #5A160D;
    border:1px solid #5A160D;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}
.message-form-input button:hover{
    background-color: transparent;
    color: #5A160D;
    border:1px solid #5A160D;
}
@media screen and (max-width:500px){
  .message-form-input button{
    width: 100% !important;
  }
}

.message-DFlex{
  display: flex;
  align-items: center;
  gap: 20px;
  grid-gap: 20px;
}
@media screen and (max-width:600px){
  .message-DFlex{
    display: block !important;
  }
}








.services-card {
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 40px;
}


.services-card h2 {
  margin-bottom: 30px;
}


.services-card p{
	display: block;
	margin-top: 10px;
  font-size: 14px !important;
  color: #3E3E3E !important;
}
.services-card h3{
	font-size: 20px;
	font-weight: 700;
	margin: 20px 0px;
}
@media screen and (max-width:500px){
  .services-card h3{
    font-size: 17px !important;
  }
}
.services-card ul{
	position: relative;
	display: block;
	width: 100%;
	margin-left: 20px;
	margin-top: 20px;
}
.services-card ul li{
	position: relative;
	width: 100%;
	margin-top: 10px;
	list-style: disc;
}



.Gabbs_sec{
  position: relative;
  width: 100%;
  height: auto;
  min-height: 90vh;
  background-color: #000;
  color: #fff;
  z-index: 1;
  display: flex;
  align-items: center;
}

.Gabbs_sec::before{
  content: '';
  position: absolute;
  width: 60%;
  height: 90%;
  top: 5%;
  right: 5%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media screen and (max-width:900px){
  .Gabbs_sec::before{
    width: 100% !important;
    height: 100% !important;
    top: 0% !important;
    left: 0% !important;
  }
}

.service1::before{
  background-image:linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.3)),url(./components/Img/Services/1.jpg);
}
.service2::before{
  background-image:linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.3)),url(./components/Img/Services/2.jpg);
}
.service3::before{
  background-image:linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.3)),url(./components/Img/Services/3.jpg);
}
.service4::before{
  background-image:linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.3)),url(./components/Img/Services/4.jpg);
}
.service5::before{
  background-image:linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.3)),url(./components/Img/Services/5.jpg);
}









.hha_Topoa{
  position: relative;
  margin-top: 40px;
}

.pp-head{
  margin-top: 30px !important;
  color: #000 !important;
}

.ggaga_Trains{
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 50px;
}



.course_Card_Ul{
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
  grid-template-rows: auto;
}
@media screen and (max-width:500px){
  .course_Card_Ul{
    display: flex !important;
    flex-direction: column !important;
    gap: 20px !important;
    grid-gap: 20px !important;
  }
}


.course_Card_Ul a,
.course_Card_Ul li{
  position: relative;
  width: 100%;
  height: auto;
  z-index: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #5A160D;
  user-select: none;
  cursor: pointer;
  padding: 20px;
  color: #fff;
}

.course_Card_Ul a:hover h3,
.course_Card_Ul li:hover h3{
  text-decoration: underline;
}


/* .course_Card_Ul li:before{
  content: '';
  position: absolute;
  width: 200px;
  height: 100px;
  border-radius: 50%;
  background-color: #CED3FE;
  z-index: -10;
  left: -20%;
  transition: all 0.2s ease-in-out;
}
.course_Card_Ul li:after{
  content: '';
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #CADBFE;
  z-index: -10;
  right: -10%;
  top: 50%;
  transition: all 0.2s ease-in-out;
}
.course_Card_Ul li:nth-of-type(2n+0){
background: #FFFBD6;
}
.course_Card_Ul li:nth-of-type(2n+0):before{
background: #FEEAB9;
}
.course_Card_Ul li:nth-of-type(2n+0):after{
background:#EDF1E6;
transform: translate(-50%, -50%);
top: 50%;
left: 50%;
}
.course_Card_Ul li:hover:before,
.course_Card_Ul li:hover:after{
    transform: scale(1.2);
}


.course_Card_Ul li:nth-of-type(5n+0):hover:after,
.course_Card_Ul li:nth-of-type(5n+0):hover:before,
.course_Card_Ul li:nth-of-type(3n+0):hover:after,
.course_Card_Ul li:nth-of-type(3n+0):hover:before,
.course_Card_Ul li:nth-of-type(2n+0):hover:after{
  transform: scale(1.2) translate(-50%, -50%);
}
.course_Card_Ul li:nth-of-type(3n+0){
background: #DCEAE0;
}
.course_Card_Ul li:nth-of-type(3n+0):before{
background:#C1E1CA;
transform: translate(-50%, -50%);
top: 30%;
left: 70%;
width: 100px;
height: 100px;
}

.course_Card_Ul li:nth-of-type(3n+0):after{
background:#B0DCBE;
transform: translate(-50%, -50%);
top: 50%;
left: 50%;
opacity: 0.5;
}
.course_Card_Ul li:nth-of-type(5n+0){
background: #F5E8DE;
}

.course_Card_Ul li:nth-of-type(5n+0):before{
background: #F7D3A9;
top: 50%;
left: 30%;
}
.course_Card_Ul li:nth-of-type(5n+0):after{
background:#F6C384;
transform: translate(-50%, -50%);
top: 50%;
left: 50%;
opacity: 0.6;
} */

.course_Card_Ul a h3,
.course_Card_Ul li h3{
  font-size: 15px;
  font-weight:400;
  line-height: 130%;
   display: -webkit-box;
  -webkit-line-clamp:3;
    line-clamp:3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  width: 100%;
}

.iia{
  border: none !important;
}


.vvaf_Sec{
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 50px;
  display: flex;
  align-items: center;
  gap: 40px;
  grid-gap: 40px;
  margin: 100px 0px !important;
}
@media screen and (max-width:1000px){
  .vvaf_Sec{
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 20px !important;
    grid-gap: 20px !important;
  }
}
.ggaga_Trains_header h2,
.vvaf_Sec h2{
  font-size: 20px;
  text-transform: uppercase;
  font-weight: normal;
}
.vvaf_Sec a{
  margin-top: 0px !important;
}
.vvaf_Box{
  position: relative;
  width: 100%;
  height: auto;
}

@media screen and (max-width:1000px){
  .vvaf_Box{
    display: flex !important;
    flex-direction: column !important;
  }
}

.vvaf_Box_Top{
  position: relative;
  width: 100%;
  height: auto;
}

@media screen and (max-width:500px){
  .vvaf_Box_Top{
    display: flex !important;
    flex-direction: column !important;
    gap: 0px !important;
    grid-gap: 0px !important;
  }
}


.course-details{
  position: relative;
  width: 100%;
  margin-top: 40px;
}

.course-details ul{
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 30px;
  margin-left: 30px;
}
.course-details ul li{
  margin-top: 10px;
  font-size: 14px;
  font-weight: normal !important;
  position: relative;
  padding-left: 20px;
}
.course-details ul li::before{
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #5A160D;
  left: -20px;
  top: 6px;
}
.course-details button{
  background-color: transparent;
  cursor: pointer;
  border-color: #5A160D !important;
  color: #5A160D !important;
}

.course-details button:hover{
  background-color: #5A160D !important;
  color: #fff !important;
}


.tag_Sec{
  position: relative;
  margin-top: 40px;
  width: 100%;
  height: auto;
}

.tag_Sec h3{
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  display: none !important;
}

.tag_Sec ul{
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
  grid-template-rows: auto;
}
@media screen and (max-width:300px){
  .tag_Sec ul{
    display: flex !important;
    flex-direction: column !important;
    gap: 20px !important;
    grid-gap: 20px !important;
  }
}

.tag_Sec ul li{
  position: relative;
  width: 100%;
  height: auto;
  padding: 20px;
  border: 1px solid #5A160D;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}





































/* gobLLa_sec */

.gobLLa_sec{
  position: relative;
  width: 100%;
  height: auto;
  background-color: #5A160D;
  color: #fff;
  margin-bottom: -50px;
  padding: 30px 0px;
}

.gobLLa_Grid{
  position: relative;
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 0.6fr 1fr;
  grid-gap: 30px;
}
@media screen and (max-width:1000px){
  .gobLLa_Grid{
    display: block !important;
    padding: 50px 0px !important;
  }
}
.gobLLa_1{
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding:80px;
}
@media screen and (max-width:1200px){
  .gobLLa_1{
    padding: 0px !important;
  }
}
.gobLLa_1 span{
  color: #fff !important;
  text-transform: uppercase;
}
.gobLLa_1 h2{
  margin: 50px 0px !important;
  font-weight: 700 !important;
  font-size: 50px !important;
  line-height: 110% !important;
}


.gobLLa_1 p{
  color: #fff !important;
  font-size: 17px !important;
}

.gobLLa_1 a{
  margin-top: 80px !important;
}
@media screen and (max-width:1000px){
  .gobLLa_1 h2{
    font-size: 40px !important;
    margin: 30px 0px !important;
  }
  .gobLLa_1 a{
    margin-top: 40px !important;
  }
}
@media screen and (max-width:700px){
  .gobLLa_1 h2{
    font-size: 30px !important;
  }

}



.gobLLa_2{
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.Industry_Card_Main,
.Latest_Industries_Slider,
.latest-industries-slider{
  position: relative;
  width: 100%;
  height: auto;
}

.latest-industries-slider {
  position: relative;
  width: 100%;
  margin: 0 auto; /* Center the slider */
  padding: 50px 20px;
}


.Latest_Industries_Slider {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.Industry_Card_Main {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.5s ease; /* Smooth transition for image scaling */
  padding: 30px;
}
@media screen and (max-width:1000px){
  .Industry_Card_Main {
    padding: 20px !important;
  }
}

@media screen and (max-width:700px){
  .latest-industries-slider{
    padding: 0px !important;
    margin-top: 30px !important;
  }
  .Industry_Card_Main {
    padding: 0px !important;
  }
}
.Industry_Card_Main_00{
  position: relative;
  width: 100%;
  height: auto;
  z-index: 1;
}

.industry-banner {
  width: 100%;
  height: 500px;
  object-fit: cover; /* Ensure image covers its container */
  transition: all 0.3s ease-in-out;
  position: relative;
  -webkit-filter: brightness(0.8);
  -moz-filter: brightness(0.8);
  filter: brightness(0.8);
  opacity: 0.4;
}
@media screen and (max-width:700px){
  .industry-banner {
    height: 400px !important;
    opacity: 1 !important;
  }
}
.Industry_Card_Main_00:hover .industry-banner{
  -webkit-filter: brightness(0.4);
  -moz-filter: brightness(0.4);
  filter: brightness(0.4);
}

/* Active slide: scale the image */
.slick-center .industry-banner {
  transform: scale(1.1); /* Enlarge image of centered slide */
  opacity: 1;
}

.Industry_Card_Dlt {
  position: absolute;
  width: 100%;
  color: #fff;
  padding: 10px 20px;
  z-index: 100;
  overflow: hidden;
  bottom: 0;
}

.Industry_Card_Dlt h3 {
  font-size: 35.3px;
  margin-bottom: 10px;
  text-transform:capitalize;
  font-weight: 400;
  transition: all 0.3s ease-in-out;
}
@media screen and (max-width:700px){
  .Industry_Card_Dlt h3 {
    font-size: 20px !important;
  }

  .Industry_Card_Dlt {
    overflow-y: auto !important;
    height: 100% !important;
  }
}

.Industry_Card_Dlt p {
  font-size: 15px;
  margin-bottom: 15px;
  opacity: 0;
  font-weight: 300;
  transition: all 0.3s ease-in-out;
  line-height: 150%;
  height: 0px;
  transform: translateY(100%);
}
.Industry_Card_Main_00:hover .Industry_Card_Dlt p{
  transform: translateY(0%);
  height: auto;
  opacity: 1;
}


.gobLLa_sec .slick-dots{
  text-align: left !important;
  bottom: -40px !important;
}

.gobLLa_sec .slick-dots li button{
  position: relative;
  width: 25px !important;
  height: 25px !important;
  border:1px solid transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50% !important;
  opacity: 0.4;
}

.gobLLa_sec .slick-dots li button::before{
  color: #fff !important;
  opacity: 1;
  padding: 0px !important;
  line-height: 0px !important;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  position: absolute !important;
  width: auto !important;
  height: auto !important;
}

.gobLLa_sec .slick-dots li.slick-active button{
  border-color: #fff !important;
  opacity: 1;
}






.Grand_GGe_Sec_Grag{
  margin-bottom: 60px !important;
}





.iso-logo{
  position: relative;
  max-width: 70px;
  margin-top: 40px;
}

.logo-slider{
  position: relative;
  width: 100%;
  height: auto;
  margin: 80px 0px;
}

@media screen and (max-width:1000px){
  .logo-slider{
    margin: 20px 0px !important;
  }
  .iso-logo{
    max-width: 50px !important;
  }
}

.logo-slider img {
  filter: grayscale(100%); /* Make the image grayscale */
  transition: all 0.3s ease-in-out; /* Smooth transition */
  width: 150px !important;
  height: 100px !important;
  object-fit: contain;
  object-position: center;
}

@media screen and (max-width:1000px){
  .logo-slider img {
    width: 100% !important;
  }
}


.logo-slider img:hover {
  filter: grayscale(0%); /* Remove grayscale on hover */
  transform: scale(1.1); /* Slight zoom on hover */
}






/* OOal_Sec */

.OOal_Sec{
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 50px;
}

.OOal_Card{
  position: relative;
  width: 100%;
  height: auto;
}

.OOal_Card_main{
  position: relative;
  width: 100%;
  height: auto;
}

.OOal_Card_Img{
  position: relative;
  width: 100%;
  height: auto;
  z-index: 1;
}

.OOal_Card_Img img{
  position: relative;
  width: 100%;
  height: 450px;
  object-fit: cover;
  -webkit-filter: brightness(0.6);
  -moz-filter: brightness(0.6);
  filter: brightness(0.6);
}

.OOal_Card_Img  h2{
  position: absolute !important;
  z-index: 10;
  bottom: 20px !important;
  margin: 50px !important;
  color: #fff !important;
  font-size: 18px !important;
  text-transform: uppercase;
  font-weight: 500;
}

.OOal_Card_Dlt{
  position: relative;
  width: 100%;
  height: auto;
  text-align: center;
  text-align: justify;
  padding: 15px;
  margin-top: 15px;
  z-index: 1;
}

.OOal_Card_Dlt::before{
  content: '';
  position: absolute;
  width: 70px;
  height: 5px;
  background-color: #6c1409;
  top:0;
  transform: translate(-50%, 0%);
  left: 50% !important;
  border-radius: 30px;
}

.OOal_Card_Dlt p{
  font-size: 15px;
  color: #525252;
  margin-top: 10px;
}


.uauaha_hHa{
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 50px;
}

.mamman_sec_main{
  position: relative;
  width: 100%;
  height: auto;
}

.OOal_Card{
  position: relative;
  width: 100%;
  height: auto;
  padding: 10px;
}

.uauaha_hHa .OOal_Card_Img img{
  opacity: 1 !important;
}



.uauaha_hHa .sss_Slider_Icons{
  grid-gap: 300px !important;
  gap: 300px !important;
  margin-top: 10px !important;
}

@media screen and (max-width:1024px){
  .uauaha_hHa .sss_Slider_Icons{
    gap: 100px !important;
    grid-gap: 100px !important;
    margin-top: 0px !important;
  }
}

.uauaha_hHa .slick-dots {
  bottom: -50px !important;
}

.read-more-btn{
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-top: 20px;
  padding:10px 0px !important;
  font-size: 14px !important;
  cursor: pointer;
  background: transparent !important;
  color: #5A160D !important;
}

















































/* Cookie Consent Banner */
.cookie-consent-banner {
  position: fixed;
  bottom: 0;
  width: 80%;
  transform: translate(-50%, 0%);
  left: 50%;
  background-color: rgba(50, 3, 3, 0.9);
  color: white;
  padding:30px !important;
  border-top-left-radius: 30px !important;
  border-top-right-radius: 30px !important;
  text-align: center;
  z-index: 9999;
}
.cookie-consent-content p{
  color: #fff !important;
}
.cookie-consent-content p a{
  text-decoration: underline !important;
}

@media screen and (max-width:1000px){
  .cookie-consent-banner {
    width: 100% !important;
    left: 50% !important;
  }
}

.cookie-consent-content h2{
  margin-bottom: 20px !important;
  font-size: 25px !important;
}

.cookie-consent-banner button {
  margin: 5px !important;
  padding: 10px 20px !important;
  background-color: #6d1c0f !important;
  border: none;
  color: white !important;
  cursor: pointer;
  font-size: 15px;
  border-radius: 4px;
  margin-top: 20px !important;
  border:1px solid transparent !important;
  transition: all 0.3s ease-in-out;
}
.cookie-consent-banner button:hover{
  opacity: 0.8;
}

.cookie-consent-banner button.reject-btn{
  background-color: transparent !important;
  border-color: #fff !important;
}
.cookie-consent-banner button.settings-btn{
  background-color: #fff !important;
  color: #000 !important;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .cookie-consent-banner {
    font-size: 14px;
    padding: 10px;
  }
  
  .cookie-consent-banner button {
    font-size: 14px;
    padding: 8px 16px;
    width: 45%;
  }
}

/* Cookie Settings Modal */
.cookie-settings-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  max-width: 500px;
  background-color: white;
  padding: 20px;
  z-index: 10000;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.cookie-settings-modal h2 {
  margin-top: 0;
  font-size: 24px;
}

.cookie-settings-modal button {
  padding: 10px 20px !important;
  background-color: #6c1409;
  color: white !important;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
}


/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .cookie-settings-modal {
    width: 90%;
    padding: 15px;
  }
  
  .cookie-settings-modal h2 {
    font-size: 20px;
  }

  .cookie-settings-modal button {
    width: 100%;
    font-size: 14px;
  }
}



















































/* Cookie Settings Modal */
.cookie-settings-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  max-width: 600px;
  background-color: white;
  padding: 20px;
  z-index: 10000;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.cookie-settings-modal h2 {
  margin-top: 0;
  font-size: 24px;
  text-align: center;
}

.cookie-settings-modal p {
  font-size: 16px;
  margin-bottom: 20px !important;
  /* text-align: center; */
  margin-top: 10px !important;
}

.cookie-settings-modal button {
  padding: 10px 20px;
  background-color: #6d1c0f;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 15px;
  display: block;
  width: 100%;
  margin: 10px 0 !important;
  transition: all 0.3s ease-in-out;
}

.cookie-settings-modal button.close-btn{
  color: #000 !important;
  background-color: #e0e0e0;
}
.cookie-settings-modal button.close-btn:hover{
  background-color: #ccc8c8 !important;
}
/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .cookie-settings-modal {
    width: 90%;
    padding: 15px !important;
  }

  .cookie-settings-modal h2 {
    font-size: 20px;
  }

  .cookie-settings-modal p {
    font-size: 14px;
  }

  .cookie-settings-modal button {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .cookie-settings-modal {
    width: 95%;
    padding: 10px;
  }

  .cookie-settings-modal h2 {
    font-size: 18px;
  }

  .cookie-settings-modal p {
    font-size: 12px;
  }

  .cookie-settings-modal button {
    font-size: 12px;
    padding: 8px 16px !important;
  }
}


.cookie-category label input{
  margin-right: 10px !important;
}
.cookie-category label span{
  display: flex;
  align-items: center;
}



















/* hahag_sec */


.hahag_sec{
  position: relative;
  width: 100%;
  height: auto;
 margin: 150px 0px;
}

.hahag_sec ul{
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 20px;
  grid-template-rows: auto;
}

@media screen and (max-width:300px){
  .hahag_sec ul{
    display: flex !important;
    flex-direction: column !important;
    align-items: center;
    gap: 20px !important;
  }
}


.hahag_sec ul li{
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 30px;
  grid-gap: 30px;
}

.hahag_sec ul li img{
  max-width: 50px;
}

.hahag_sec ul li p{
  font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
}


.haha_learn{
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}








.nahh_Bban{
  position: absolute;
  width: 100%;
  height: 100px;
  background-color: #000;
  z-index: -1000;
  top: 0;
  left: 0;
}


.haaha_hhaa{
  margin-top: 150px !important;
  padding: 0px 100px !important;
  text-align: justify !important;
}


.haaha_hhaa h3{
  font-size: 15px !important;
  color: #6d1c0f !important;
  text-transform: uppercase !important;
}

@media screen and (max-width:1100px){
  .nahh_Bban{
    height: 70px !important;
  }
  .haaha_hhaa{
    margin-top: 50px !important;
    padding: 0px !important;
  }
}






























/* service-bg */

.service-bg{
  background-color: #000;
  background-image:linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.3)),url(./components//Img/servic-bg.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
  padding: 200px 0px !important;
}


.ahahha_Sec{
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 50px;
  margin-bottom: 100px !important;
  padding: 0px 100px;
}
@media screen and (max-width:1200px){
  .ahahha_Sec{
    padding: 0px !important;
  }
  .ahahha_Box{
    padding: 50px !important;
  }
}
.ahahha_Box{
  position: relative;
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-gap: 40px;
  padding: 50px 140px;
  border-bottom: 1px solid #ccc;
  margin-top: 50px;
  transition: all 0.5s ease-in-out;
}

@media screen and (max-width:1000px){
  .ahahha_Box{
    display: flex !important;
    flex-direction: column !important;
    grid-area: 30px !important;
    gap: 30px !important;
    padding: 40px 0px !important;
    margin-top: 40px !important;
  }
  .ahahha_Box_1 img{
    height: auto !important;
  }
}

.ahahha_Box:hover{
  background-color: #f2f2f2;
}

.ahahha_Box_1{
  position: relative;
  width: 100%;
  height: auto;
}

.ahahha_Box_1 img{
  position: relative;
  width: 100%;
  height: 280px;
  object-fit: cover;
}

.ahahha_Box_2{
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}

.ahahha_Box_2 h2{
  text-transform: uppercase;
  font-size: 25px;
  margin-bottom: 30px;
  font-weight: 400 !important;
}
@media screen and (max-width:500px){
  .ahahha_Box_2 h2{
    font-size: 20px !important;
  }
}
.ahahha_Box_2 p{
  margin-bottom: 30px !important;
  color: #3E3E3E !important;
}

.ahahha_Box_2 span{
  font-size: 14px;
  font-weight: 600;
  transition: all 0.5s ease-in-out;
}

.ahahha_Box:hover .ahahha_Box_2 span{
  color: #79180b;
}





.ploc-ahhs{
  text-decoration: underline !important;
  color: #79180b !important;
  font-weight: 600 !important;
}
.closha-ul{
  margin-left: 20px !important;
}
.closha-ul  li{
  font-size: 14px !important;
  list-style: disc !important;
  margin: 10px 0px !important;
}

.HHga_2_Box_Dlt h4{
  display: block;
  margin-top: 20px !important;
  font-size: 15px !important;
}